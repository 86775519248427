export const sendEmailConfirmationRequest = async (body) => {
  return fetch(
    "https://us-central1-popsto-re.cloudfunctions.net/orderCreated",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  )
}
