import React, { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import firebase, {
  doc,
  getDoc,
  db,
  collection,
  getDocs,
} from "../../service/firebase"
import { useNavigate, useParams } from "react-router-dom"
import textToPriceParser from "../../functions/textToPriceParser"
import BackButton from "../../components/BackButton/BackButton.tsx"
import { Alert } from "@mui/material"

const OrdersPage = () => {
  const navigate = useNavigate()
  const { storeId, ownerId } = useParams()
  const [store, setStore] = useState({})
  const [hasUnconfirmedOrders, setHasUnconfirmedOrders] = useState(false)
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const storesRef = collection(db, `/StoreOwners/${ownerId}/allStores`)
        const store = await getDoc(doc(storesRef, storeId))
        if (store.exists()) {
          let data = store.data()
          data.columnsList = JSON.parse(data.columnsList)
          setStore(data)
        }
        const ordersRef = collection(
          db,
          `/StoreOwners/${ownerId}/allStores/${storeId}/Orders`,
        )
        const querySnapshot = await getDocs(ordersRef)
        let temp = []
        querySnapshot.forEach((doc) => {
          let d = doc.data()
          if (!d.confirmed) setHasUnconfirmedOrders(true)
          if (!d.confirmed) return
          d.order = JSON.parse(d.order)
          temp.push(d)
        })
        let orders = []
        temp.forEach((o) => {
          o.order.forEach((p) => {
            if (p !== null) {
              // check if order already exists
              let index = orders.findIndex((e) => e.id === p.id)
              if (index === -1) {
                orders.push(p)
              } else {
                orders[index].quantity += p.quantity
              }
            }
          })
        })
        setOrders(orders)
        setIsLoading(false)
      } else {
        navigate("/")
      }
    })
  }, [navigate, storeId, ownerId])

  if (isLoading) return null
  if (orders.length < 1)
    return (
      <>
        <BackButton />
        <Typography gutterBottom variant="h4">
          Orders
        </Typography>
        <Typography gutterBottom variant="h6">
          No orders yet
        </Typography>
      </>
    )
  return (
    <>
      <div className="popstore-wrapper">
        <BackButton />
        {hasUnconfirmedOrders && (
          <Alert severity="info">
            <Typography>
              This store has unconfirmed orders that is not shown in this list.
            </Typography>
          </Alert>
        )}
        <Grid className="pop-header-wrapper" container spacing={2}>
          <Grid item xs={4} md={4}>
            <Typography style={{ marginBottom: "20px" }} variant="h4">
              Orders List
            </Typography>
          </Grid>
        </Grid>
        <Grid className="pop-header-wrapper" container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography style={{ marginBottom: "1rem" }} variant="h6">
              {store?.storeName}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={5} md={5}>
            <h5>Product</h5>
          </Grid>
          <Grid item display={{ xs: "none", md: "grid" }} md={2}>
            <h5>Price</h5>
          </Grid>
          <Grid item xs={4} md={3}>
            <h5>Quantity</h5>
          </Grid>
          <Grid item xs={3} md={2}>
            <h5>Total</h5>
          </Grid>
        </Grid>
        {orders?.map((order, index) => {
          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={5} md={5}>
                <p>{store.columnsList[order.id][1]}</p>
              </Grid>
              <Grid item display={{ xs: "none", md: "grid" }} xs={0} md={2}>
                <p>
                  {textToPriceParser(store.columnsList[order.id][2])}{" "}
                  {store?.currency}
                </p>
              </Grid>
              <Grid item xs={4} md={3}>
                <p>{order.quantity}</p>
              </Grid>
              <Grid item xs={3} md={2}>
                <p>
                  {(
                    textToPriceParser(store.columnsList[order.id][2]) *
                    parseFloat(order.quantity)
                  ).toFixed(2)}{" "}
                  {store?.currency}
                </p>
              </Grid>
            </Grid>
          )
        })}
        <Grid container spacing={2}>
          <Grid item xs={5} md={7} textAlign="right">
            <p>&nbsp;</p>
          </Grid>
          <Grid item xs={4} md={3}>
            <h4>Grand Total</h4>
          </Grid>
          <Grid item xs={3} md={2}>
            <h4>
              {orders
                ?.reduce((prev, next) => {
                  return (
                    prev +
                    textToPriceParser(store.columnsList[next.id][2]) *
                      parseFloat(next.quantity)
                  )
                }, 0)
                .toFixed(2)}{" "}
              {store?.currency}
            </h4>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default OrdersPage
