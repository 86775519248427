import {
  IconButton,
  TableCell,
  TableRow as MuiTableRow,
  Alert,
  Typography,
} from "@mui/material"
import React, { useState } from "react"
import { ICustomer } from "../../types/ICustomer"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import CustomerDetails from "./CustomerDetails.tsx"
import { useParams } from "react-router-dom"
import { useGetStore } from "../../hooks/firestore/useGetStore.ts"
import { useGetStoreOrders } from "../../hooks/firestore/useGetStoreOrders.ts"
import { mapOrderItemsToStoreColumns } from "./functions/mapOrderItemsToStoreColumns.ts"
import { stackCustomerItems } from "./functions/stackCustomerItems.ts"
import { getTotalPrice } from "./functions/getTotalPrice.ts"
import { Check } from "@mui/icons-material"

interface ITableRowProps {
  customer: ICustomer
}

const TableRow: React.FC<ITableRowProps> = ({ customer }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { storeId, ownerId } = useParams()
  const { store } = useGetStore(ownerId, storeId)
  const { storeOrders, hasUnconfirmedOrders } = useGetStoreOrders(
    ownerId,
    storeId,
    customer?.email,
  )
  const orders =
    store && storeOrders ? mapOrderItemsToStoreColumns(store, storeOrders) : []
  const items = stackCustomerItems(orders)
  return (
    <>
      <MuiTableRow>
        <TableCell sx={{ width: "20px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{customer.name}</TableCell>
        <TableCell>{customer.email}</TableCell>
        <TableCell>{customer.phone}</TableCell>
        <TableCell>{hasUnconfirmedOrders && <Check />}</TableCell>
        <TableCell align="right">
          {getTotalPrice(items)} {store?.currency}
        </TableCell>
      </MuiTableRow>
      {open && <CustomerDetails store={store} items={items} open={open} />}
    </>
  )
}

export default TableRow
