import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow as MuiTableRow,
} from "@mui/material"
import React from "react"
import { ICustomer } from "../../types/ICustomer"
import useDeviceSize from "../../hooks/useDeviceSize.ts"
import CustomersListCompact from "./CustomersListCompact.tsx"
import TableRow from "./TableRow.tsx"

interface ICustomersListProps {
  customers: ICustomer[]
}

const CustomersList: React.FC<ICustomersListProps> = ({ customers }) => {
  const { mediumScreensUp } = useDeviceSize()
  if (mediumScreensUp)
    return (
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <MuiTableRow>
              <TableCell sx={{ width: "20px" }} />
              <TableCell>Name</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Unconfirmed orders</TableCell>
              <TableCell align="right">Customer total</TableCell>
            </MuiTableRow>
          </TableHead>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.email} customer={customer} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  else return <CustomersListCompact customers={customers} />
}

export default CustomersList
